body.admin {
	line-height: 24px;
	font-size: 13px;
	font-family: $primary-font;
	font-weight: 400;
	color: $text-color;
	background: url(../img/body-bg.jpg) no-repeat center;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-position: top;
	@media (max-width: 1199px) and (min-width: 992px) {
		font-size: 11px;
		line-height: 22px;
	}
}

@-webkit-keyframes autofill {
    to {
        color: $text-color;
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
	color: $primary-color;
	line-height: 1.5;
}
h1 {
	font-size: 22px;
}
h2 {
	font-size: 20px;
}
h3 {
	font-size: 18px;
}
h4 {
	font-size: 16px;
}
h5 {
	font-size: 14px;
}
h6 {
	font-size: 12px;
}

.list {
	list-style: none;
	margin: 0px;
	padding: 0px;
}

a {
	text-decoration: none;
	@include transition(all 0.3s ease-in-out);
	&:hover,
	&:focus {
		text-decoration: none;
		outline: none;
	}
}

textarea {
	overflow: hidden;
	resize: none;
}
input,
textarea {
	@include placeholder {
		position: relative;
		bottom: -5px;
	}
}

button:focus {
	outline: none;
	box-shadow: none;
}

// Background Color
.transparent-color {
	background: transparent !important;
}

// Color
.primary-color {
	color: $primary-color;
}
.primary-color2 {
	color: $primary-color2;
}
.black-color {
	color: $black;
}
.text-color {
	color: $text-color;
}

.exam-bg {
	background: rgba($text-color, .3);
}

// Font- Weight
.fw-400 {
	font-weight: 400;
}
.fw-500 {
	font-weight: 500;
}
.fw-600 {
	font-weight: 600;
}

// Font Size
.fs-12 {
	font-size: 12px;
}

// Margin Bottom Class
.mb-10 {
	margin-bottom: 10px;
}
.mb-15 {
	margin-bottom: 15px;
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-25 {
	margin-bottom: 25px;
}
.mb-30 {
	margin-bottom: 30px;
}
.mb-35 {
	margin-bottom: 35px;
}
.mb-40 {
	margin-bottom: 40px;
}
.mb-45 {
	margin-bottom: 45px;
}
.mb-50 {
	margin-bottom: 50px;
}

// Margin Left Class
.ml-10 {
	margin-left: 10px;
}
.ml-15 {
	margin-left: 15px;
}
.ml-20 {
	margin-left: 20px;
}
.ml-25 {
	margin-left: 25px;
}
.ml-30 {
	margin-left: 30px;
}
.ml-35 {
	margin-left: 35px;
}
.ml-40 {
	margin-left: 40px;
}
.ml-45 {
	margin-left: 45px;
}
.ml-50 {
	margin-left: 50px;
}

// Margin Right Class
.mr-10 {
	margin-right: 10px;
}
.mr-15 {
	margin-right: 15px;
}
.mr-20 {
	margin-right: 20px;
}
.mr-25 {
	margin-right: 25px;
}
.mr-30 {
	margin-right: 30px;
}
.mr-35 {
	margin-right: 35px;
}
.mr-40 {
	margin-right: 40px;
}
.mr-45 {
	margin-right: 45px;
}
.mr-50 {
	margin-right: 50px;
}
.mr-75 {
	margin-right: 75px;
}

// Margin TOp Class
.mt--48 {
	margin-top: -48px;
}
.mt-10 {
	margin-top: 10px;
}
.mt-15 {
	margin-top: 15px;
}
.mt-20 {
	margin-top: 20px;
}
.mt-25 {
	margin-top: 25px;
}
.mt-30 {
	margin-top: 30px;
}
.mt-35 {
	margin-top: 35px;
}
.mt-40 {
	margin-top: 40px;
}
.mt-45 {
	margin-top: 45px;
}
.mt-50 {
	margin-top: 50px;
}
.mt-80 {
	margin-top: 80px;
}

// Padding Bottm
.pb-7 {
	padding-bottom: 7px !important;
}
.pb-10 {
	padding-bottom: 10px !important;
}
.pb-20 {
	padding-bottom: 20px !important;
}
.pb-30 {
	padding-bottom: 30px !important;
}
.pb-40 {
	padding-bottom: 40px !important;
}
.pb-50 {
	padding-bottom: 50px !important;
}
.pb-120 {
	padding-bottom: 120px !important;
}

// Padding Left
.pl-10 {
	padding-left: 10px;
}
.pl-20 {
	padding-left: 20px;
}
.pl-30 {
	padding-left: 30px;
}
.pl-35 {
	padding-left: 35px;
}
.pl-40 {
	padding-left: 40px;
}
.pl-50 {
	padding-left: 50px;
}

// Padding Vertical
.pv-10 {
	padding-top: 10px;
	padding-bottom: 10px;
}

// Padding Right
.pr-30 {
	padding-right: 30px;
}
// Padding Top
.pt-10 {
	padding-top: 10px;
}
.pt-20 {
	padding-top: 20px;
}
.pt-30 {
	padding-top: 30px;
}

// Padding Horizontally Class
.p-h-20 {
	padding: 0px 16px;
}
