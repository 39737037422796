/*----------------------------------------------------
@File: Default Styles
@Author: SPONDON IT

This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.
---------------------------------------------------- */

/*=====================================================================
@Template Name: HostHub Construction 
@Developed By: Naim Lasker
Author E-mail: naimlasker@gmail.com

=====================================================================*/

/*----------------------------------------------------*/

@import "variables";
/*---------------------------------------------------- */

@import "mixins";
/*---------------------------------------------------- */

@import "admin/reset";
/*---------------------------------------------------- */

@import "admin/responsive";
/*---------------------------------------------------- */

@import "admin/component";
/*---------------------------------------------------- */

@import "admin/predefine";
/*---------------------------------------------------- */

@import "admin/login";
/*---------------------------------------------------- */

@import "admin/sidebar";
/*---------------------------------------------------- */

@import "admin/header";
/*---------------------------------------------------- */

@import "admin/student";
/*---------------------------------------------------- */

@import "admin/front-cms";
/*---------------------------------------------------- */

@import "admin/exam";
/*---------------------------------------------------- */

@import "admin/fees";
/*---------------------------------------------------- */

@import "admin/settings";
/*---------------------------------------------------- */

@import "admin/home";
/*---------------------------------------------------- */

@import "admin/footer";
/*---------------------------------------------------- */

@import "client/reset";
/*---------------------------------------------------- */

@import "client/predefine";
/*---------------------------------------------------- */

@import "client/header";
/*---------------------------------------------------- */

@import "client/home";
/*---------------------------------------------------- */

@import "client/footer";
/*---------------------------------------------------- */
@import "update";
/*---------------------------------------------------- */