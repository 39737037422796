@media (max-width: 991px) {
	.mb-20-lg {
		margin-bottom: 20px;
	}
	.mb-30-lg {
		margin-bottom: 30px;
	}
	.mt-20-lg {
		margin-top: 20px;
	}
	.mt-30-lg {
		margin-top: 30px;
	}
}

@media (max-width: 767px) {
	.mt-30-md {
		margin-top: 30px;
	}
	.mt-30-md {
		margin-top: 30px;
	}
	.mt-50-md {
		margin-top: 50px;
	}
}
