/*********************************************
************* custom css {update} ************
*********************************************/
$extra_big: 'only screen and (min-width: 1440px) and (max-width: 1658px)';
$big_screen:'only screen and (min-width: 1200px) and (max-width: 1440px)';
$medium : 'only screen and (min-width: 992px) and (max-width: 1200px)';
$tab:'only screen and (min-width: 768px) and (max-width: 991px)';
$large: 'only screen and (min-width: 576px) and (max-width: 767px)';
$all_tab:'(max-width: 991px)';
$all_bg_tab:'(min-width: 991px)';
$small:'(max-width: 575px)';
$all_big_screen:'(min-width: 991px)';
$white: #fff;
$btn_bg: #7c32ff;
.update_menu{
    .navbar .navbar-toggler {
        color: #ffffff;
        font-size: 20px;
        border: 1px solid #fff;
        border-radius: 0;
        padding: 7px 10px;
    }
    .ti-menu:before {
        content: "\e6c1";
        font-size: 20px;
        color: #fff;
    }
    .search-bar{
        .input-group{
            max-width: 305px;
            float: right;
        }
        input{
            padding-bottom: 6px;
            padding-left: 40px;
            ::placeholder{
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
            }
        }
        span{
            font-size: 20px;
            background-color: rgb(65, 80, 148);
            padding-top: 4px;
        }
    }
    @media #{$all_tab}{
        height: 80px;
        position: relative;
        padding: 10px !important;
        .light_logo{
            max-width: 100px;
        }
        .menu_nav {
            background-color: #415094;
        }
        .navbar .nav .nav-item .nav-link {
            padding: 10px 10px !important;
        }
    }
}
.academic-img{
    img{
        width: 100%;
    }
}
.client .header-area .navbar .search-bar input:focus {
    color: #828bb2 !important;
    font-weight: 300;
}
@media #{$all_tab}{
    .client.light .header-area .navbar .nav .nav-item .nav-link, .client.color .header-area .navbar .nav .nav-item .nav-link {
        color: $white !important;
    }
    .single-testimonial {
        padding-bottom: 0 !important;
    }
    .client .news-area {
        margin-bottom: 0;
    }
    .client .mapBox {
        height: 350px;
    }
    .client .section-gap-top {
        padding-top: 40px;
    }
    .contact_area {
        margin-bottom: 40px;
    }
    .client .banner-area {
        margin-top: 85px;
    }
    .events-area{
        .date{
            line-height: 18px; 
        }
    }
} 

@media #{$medium}{
    .update_menu{
        .update_menu .menu_nav {
            margin-left: 10% !important;
        }  
        .navbar-brand{
            img{
                max-width: 100px;
            }
        }
    }
    
}

@media #{$all_bg_tab}{
    .update_menu{
        .menu_nav{
            margin-left: 25%;
        }
        .btn-dark:hover {
            color: #fff;
            background-color: #23272b;
            border-color: transparent;
        }
    }
}
.login-area table td {
    padding: 0px 7px 0px 7px;
    width: 25% !important;
    @media #{$small}{
        width: 50% !important;
        display: inline-block;
    }
    @media #{$large}{
        width: 50% !important;
        display: inline-block;
    }
    .get-login-access{
        padding: 5% 10%;
        color: #415094;
        letter-spacing: 1px;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        outline: none !important;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        border: 0;
        border-radius: 5px;
        overflow: hidden;
        -webkit-transition: all 0.4s ease 0s;
        -moz-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
        background-color: #ffffff;
        &:hover{
            background: linear-gradient(90deg, #7c32ff 0%, #c738d8 100%);
            color: #ffffff;        
        }
    }
}


/************************************************
***************** login css *********************/
.login.admin.hight_100{
    .login-height {
        .input-group-addon{
            width: 0;
        }
        .form-group i {
            top: 7px;
            left: 4px;
        }
    }
    @media #{$small}{
        .login-height {
            .input-group-addon{
                width: 0;
            }
            .form-group i {
                top: 7px;
                left: 4px;
            }
            .form-wrap {
                padding: 50px 8px;
            }
            a{
                font-size: 12px;
            }
        }
    }
    @media #{$large}{
        .login-height {
            .input-group-addon{
                width: 0;
            }
            .form-group i {
                top: 7px;
                left: 4px;
            }
        }
    }
    @media #{$all_tab}{
        height: 100% !important; 
        overflow: visible;
        .login-height {
            .input-group-addon{
                width: 0;
            }
            .form-group i {
                top: 7px;
                left: 4px;
            }
        }
    }    
}
.hight_100{
    height: 100vh;
    @media #{$all_tab}{
        height: 100% !important; 
    }
    @media #{$big_screen}{
        height: 100% !important; 
    }   
    @media #{$medium}{
        height: 100% !important; 
    }  
}

@media #{$tab}{
    .login-area .login-height {
        min-height: auto;
    }
    .login-height{
        margin: 50px 0;
    }
}

/******************************************************/
/**************** dashboard css ******************/
/******************************************************/

//dashboard menu css
.main-title{
    @media #{$all_tab}{
        margin-top: 20px;
    } 
}
.white-box.single-summery{
    margin-top: 30px;
    @media #{$all_tab}{
        margin-top: 20px;
    } 
    @media #{$small}{
        padding: 10px 15px;
        h3{
            margin-bottom: 0;
        }
        
    }
    @media #{$medium} {
        min-height: 172px;
        .d-flex {
            display: block !important;
        }
    }
}
.nav_icon{
    @media #{$all_tab}{
        background: #7c32ff !important;
        border: 1px solid #7c32ff;
        i{
            font-size: 24px;
            padding: 4px 0px 0px;
            display: inline-block;
        
        }
        .ti-more{
            padding: 6px 0 0;
        }
    }
}
//dashboard sidebar css
#sidebar{
    @media #{$small}{
        max-width: 80%;
        margin-left: -80%;
        min-width: 80%;
        z-index: 9999 !important;
    }
}
#sidebar.active {
    z-index: 99999;
}
#close_sidebar{
    cursor: pointer;
}
.admin .navbar {
    @media #{$small}{
        z-index: 999;
    }
}
.update_sidebar{
    display: flex;
    justify-content: space-between;
    img{
        max-width: 100px !important;
    }
    i{
        font-size: 15px;
        color: #fff;
        background: #7c32ff !important;
        border: 1px solid #7c32ff;
        display: inline-block;
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
    }
    .close_sidebar{
        display: none;
    }
}
.up_dashboard{
    @media #{$all_tab}{
        .main-title{
            h3{
                margin-top: 30px;
                line-height: 25px;
            }
        }
    }
}
.up_dash_menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media #{$all_tab}{
        width: auto;
    }
    @media #{$large}{
        width: 97%;
    }
    @media #{$tab}{
        width: 100%;
    }
    ul.nav.navbar-nav.mr-auto.nav-buttons{
        margin: 0 auto !important;
        text-align: center !important;
        @media #{$small}{
            text-align: left !important;
        }
    }
}
.search-bar {
    @media #{$all_tab}{
        margin-bottom: 0;
        padding-left: 18%;
    }
    @media #{$small}{
        padding-left: 0%;
        width: 58%;
        margin: 0 auto;
        text-align: center;
    }
    @media #{$large}{
        padding-left: 6%;
    } 
    @media #{$tab}{
        padding-left: 11%;
    }
    margin: 0 auto;
    text-align: center;
}
@media #{$all_tab}{
    .up_navbar{
        width: 97% !important;
        .btn-dark:hover, .btn-dark:focus {
            border-color: transparent;
            outline: 0;
        }
    }
    .up_dash_menu{
        .navbar-collapse {
            margin-top: 10px;
            padding: 30px;
            background: url(../img/body-bg.jpg) no-repeat right;
            position: absolute;
            width: 97%;
            top: 42px;
        }
    }
}
@media #{$small}{
    .up_navbar{
        width: 91% !important;
        .col-lg-12{
            padding-left: 0;
            padding-right: 0;
        }
    }
    .up_dash_menu{
        .navbar-collapse {
            width: 100%;
        }
    }
}
@media #{$large}{
    .up_navbar{
        width: 91% !important;
        .col-lg-12{
            padding-left: 0;
            padding-right: 0;
        }
    }
    .up_dash_menu{
        .navbar-collapse {
            width: 100%;
        }
    }
}
.up_ds_margin{
    @media #{$all_tab}{
        margin-bottom: 15px;
    }
    .ti-close{
        line-height: 30px;
    }
}
.up_buttom{
    display: flex;
    justify-content: space-between;
}
.up_toList{
    margin-bottom: 9px;
    @media #{$medium}{
        .text-right {
            text-align: right !important;
        }
    } 
    @media #{$all_tab}{
        margin-top: 20px;
        .main-title {
            margin-top: 0;
        }
    }

}

//breadcrumb css
.up_breadcrumb{
    @media #{$all_tab}{
        margin: 40px 0 20px;
    }
}

.up_admin_visitor{
    @media #{$all_tab}{
        .dataTables_filter > label{
            left: 47%;
            min-width: 280px;
            position: relative;
            top: -8px;        
        }
        div.dt-buttons{
            // bottom: 0;
            // text-align: center;
            // margin-bottom: 20px;
            display: none;
        }
        .main-title {
            margin: 40px 0 20px;
        }
    }
    @media #{$large}{
        .dataTables_filter > label{
            left: 1%;      
        }
    }
    @media #{$tab}{
        .dataTables_filter > label{
            left: -12%;      
        }
        
    }
    @media #{$medium}{
        .dataTables_wrapper .dataTables_filter input{
            width: 70%;
        }
        .dataTables_filter > label{
            left: 47%;   
        }
    }
}
.up_st_admin_visitor{
    @media #{$all_tab}{
        .dataTables_filter > label{
            left: 47%;
            min-width: 280px;
            position: relative;
            top: -8px;        
        }
        div.dt-buttons{
            // bottom: 0;
            // text-align: center;
            // margin-bottom: 20px;
            display: none;
        }
        .main-title {
            margin: 40px 0 20px;
        }
    }
    @media #{$large}{
        .dataTables_filter > label{
            left: 1%;      
        }
    }
    @media #{$tab}{
        .dataTables_filter > label{
            left: -12%;      
        }
        
    }
    @media #{$medium}{
        .dataTables_wrapper .dataTables_filter input{
            width: 70%;
        }
        .dataTables_filter > label {
            margin-bottom: 20px;
            position: relative;
            top: 0px;
            left: 20%;
            transform: translateX(-50%);
            min-width: 280px;
            border-bottom: 1px solid rgba(130, 139, 178, 0.4);
            margin-top: 20px;
        }
    }
    
}
.sms-breadcrumb{
    @media #{$small}{
        margin: 40px 0 20px;
    }
}
.fc-state-active, .fc-state-down {
    background-color: #ccc;
    background-image: none;
    box-shadow: none;
}
.main-title {
    @media #{$all_tab}{
        margin-top: 0;
        margin-bottom: 15px;
    }
}
.fc .fc-button-group > * {
    float: left;
    margin: 0 0 10px 10px;
    border-radius: 30px;
    padding: 0px 8px;
}
.sms-breadcrumb{
    @media #{$all_tab}{
        margin: 30px 0 20px;
    }
}
.mb-40.up_dashboard {
    @media #{$all_tab}{
        margin-bottom: 20px;
    }
}

@media #{$small}{
    .fc-toolbar.fc-header-toolbar {
        .fc-left, .fc-right, .fc-center{
            display: block;
            width: 100%;
            text-align: center;
        }
    }
}
@media #{$all_tab}{
    .mt-40{
        margin-top: 15px;
    }
    .mb-30-lg {
        margin-bottom: 0;
    }
    .student-details{
        margin-top: 50px;
    }
}
.search_bar {
	position: absolute;
	margin: auto;
	top: 0;
	right: 0;
	bottom: 0;
	width: auto;
	height: 100px;
	z-index: 999;

	.search {
		position: absolute;
		margin: auto;
		top: 0;
		right: 0;
		bottom: 0;
		width: 40px;
		height: 40px;
		transition: all .5s;
		z-index: 4;
		font-size: 18px;

		&:hover {
			cursor: pointer;
		}

		&::before {
			content: "\e610";
			position: absolute;
			margin: auto;
			top: 10px;
			right: 15px;
			bottom: 0;
			width: 6px;
			transition: all .5s;
			font-family: 'themify';
		}
	}

	input {
		position: absolute;
        margin: auto;
        top: 25px;
        right: 0;
        width: 0px;
        height: 50px;
        outline: none;
        border: none;
		z-index: 99;
		// border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		background: #fe005f;
		color: white;
		padding: 10px;
		transition: all .5s;
		opacity: 0;
		z-index: 5;
		font-weight: bolder;
		letter-spacing: 0.1em;
		&:hover {
			cursor: pointer;
		}

		&:focus {
			width: 280px;
			opacity: 1;
			cursor: text;
			padding-left: 15px;
		}

		&:focus~.search {
			right: 5px;
			background: #fff;
			z-index: 6;
			padding: 0 20px 0 20px;
			&::before {
				top: 8px;
                right: 20px;
				content: "\e646";
				font-family: 'themify';
				
			}
		}

		&::placeholder {
			color: white;
			opacity: 1;
			font-weight: bolder;
		}
	}
}

.logoimage{
    max-width: 150px !important;
     height: auto;
     padding: 2px;
   }
   .loginButton{
       display: flex;
       flex-wrap: wrap;
       justify-content: space-between;
   }
   .singleLoginButton{
       flex: 22% 0 0;
   }
   
   .loginButton .get-login-access {
       display: block;
       width: 100%;
       border: 1px solid #fff;
       border-radius: 5px;
       margin-bottom: 20px;
       padding: 5px;
   }
   @media (max-width: 576px) {
     .singleLoginButton{
       flex: 49% 0 0;
     }
   }
   @media (max-width: 576px) {
     .singleLoginButton{
       flex: 49% 0 0;
     }
   }
   
   .dialog-notice-title{
       text-align: left !important;
       color: white !important;
       text-shadow: 0px 10px 10px black !important;
   }
   
   
   @media #{$all_big_screen}{
    .up_dashboard{
        .col-lg-3, .col-6{
            &:nth-child(-n+4){
                .single-summery{
                     margin-top: 0 !important;  
                }
            }
            
        }
    } 
   }
   @media #{$all_tab}{
    .single-summery{
        h3{
            font-size: 16px;
            text-transform: uppercase;
        }
    }
    
   } 
   @media #{$large}{
    .up_dashboard{
        .col-lg-3, .col-6{
            &:nth-child(-n+2){
                .single-summery{
                     margin-top: 0 !important;  
                }
            }
        }
    } 
   } 
    @media #{$tab}{
    .up_dashboard{
        .col-lg-3, .col-6{
            &:nth-child(-n+2){
                .single-summery{
                     margin-top: 0 !important;  
                }
            }
        }
    } 
   } 

   @media #{$small}{
    .up_sm_btn{
        padding: 0 8px;
    }
    .up_dashboard{
        .col-lg-3, .col-6{
            &:nth-child(1){
                .single-summery{
                     margin-top: 0 !important;  
                }
            }
        }
    } 
   }
   @media #{$medium}{
    .up_md_btn{
        padding: 0 6px;
    }
    .main-title h3 {
        font-size: 15px;
    }
   }
   .client_img{
        max-width: 80px;
        border-radius: 50%;
   }
   #table_id_wrapper{
       .dataTables_length{
           label{
               margin: 20px 0 10px;
           }
           select{
            width: 80px;
            border-radius: 0 !important;
            border: 1px solid $primary-color2;
           }
       }
   }
   select:focus {
    outline: -webkit-focus-ring-color auto 0;
   } 
   .school-table-style .dropdown-item{
       padding: 0 !important;
   }
   @media #{$medium}{
       .mb_2{
           margin-bottom: 10px;
       }
   }
   @media (min-width: 991px) and (max-width: 1200px){
    .add-visitor{  
        padding: 0; 
    }
    @media #{$all_tab}{
        #table_id_filter > label{
             display: none !important;
        }
    } 
   
}
@media #{$all_tab}{
    .col-lg-9{
        .main-title {
            h3{
                margin: 30px 0 20px !important;
            }
            
        }
    }
}
@media #{$all_tab}{
    .single_invoice{
        margin: 20px 0;
        .up_select_modal {
            position: absolute;
            right: 23px;
            font-size: 14px;
            color: #828bb2;
            top: 30px;
            font-weight: 700;
        }
    }
}
@media #{$tab}{
    .mt-30-md{
        margin-top: 30px;
    }
}
@media #{$small}{
    table.dataTable > tbody > tr.child span.dtr-data {
        min-height: 47px;
        padding: 11px 4px;
        display: block;
        min-width: 75px;
        border-bottom: 1px solid rgba(130, 139, 178, 0.3);
    }
    .invoice_table{
        margin-top: 20px !important;
        thead th {
            padding-left: 18px;
            display: block;
        }
        thead{
            td{
                width: 50%;
                display: inline-block;
            }
        }
        
        tr{
            display: block;
        }
        .product_table{
            td{
                width: 100%;
                display: inline-block;
            }
        }
        thead {
            background: rgba(130, 139, 178, 0.3);
            display: table-caption;
            width: initial;
        }
    }
}
@media #{$all_tab}{
    .staff{
        .input-effect{
            margin: 15px 0;
        }
    }
}