/*font Variables*/
$primary-font: 'Poppins', sans-serif;

/*Color Variables*/
$primary-color: #415094;
$primary-color2: #7c32ff;
$primary-color3: #c738d8;
$title-color: #222222;
$text-color: #828bb2;
$white: #ffffff;
$black: #000000;

/*=================== fonts ====================*/
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,400i,500,600');
