body.login {
	background: url(../img/login-bg.jpg) no-repeat center;
	background-size: cover;
	.footer_area {
		background: transparent;
		border: 0;
		@media (max-width: 1199px) {
			text-align: center;
		}
		a {
			color: $white;
		}
	}
}

.login-area {
	a {
		color: $text-color;
		&:hover {
			opacity: .8;
		}
	}
	.login-height {
		min-height: 95vh;
	}
	.form-wrap {
		background: rgba(28, 0, 78, 0.25);
		padding: 50px 70px;
		@media (max-width: 1199px) and (min-width: 992px) {
			padding: 50px 20px;
		}
		@media (max-width: 480px) {
			padding: 50px 20px;
		}
	}
	.logo-container {
	}
	h5 {
		margin-top: 40px;
		margin-bottom: 25px;
		color: $white;
		letter-spacing: 2px;
		font-size: 14px;
		font-weight: 700;
	}
	.form-group {
		.form-control {
			color: $text-color;
			border: 0px;
			border-bottom: 1px solid rgba(247, 247, 255, 0.2);
			border-radius: 0px;
			background: transparent!important;
			padding: 0px 30px;
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 1px;
			&:focus {
				outline: none;
				box-shadow: none;
			}
			@include placeholder {
				position: relative;
				left: 0px;
				top: 0px;
				font-size: 12px;
				font-weight: 400;
				text-transform: uppercase;
				color: $text-color;
				letter-spacing: 1px;
			}
		}
		a {
			font-size: 12px;
			font-weight: 700;
		}
		i {
			color: $text-color;
			display: inline-block;
			position: relative;
			top: 6px;
			left: 14px;
			font-size: 12px;
			font-weight: 400;
		}
	}
	.checkbox {
		input {
			margin-right: 6px;
		}
	}
	/*Checkboxes styles*/
	input[type="checkbox"] {
		display: none;
	}
	input[type="checkbox"] + label {
		display: block;
		position: relative;
		padding-left: 25px;
		margin-bottom: 20px;
		font: 12px/20px $primary-font;
		color: $text-color;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}
	input[type="checkbox"] + label:last-child {
		margin-bottom: 0;
	}
	input[type="checkbox"] + label:before {
		content: '';
		display: block;
		width: 12px;
		height: 12px;
		border: 2px solid $text-color;
		border-radius: 50px;
		position: absolute;
		left: 0;
		top: 4px;
		opacity: .6;
		@include transition();
	}
	input[type="checkbox"]:checked + label:before {
		width: 8px;
		top: 1px;
		left: 5px;
		border-radius: 0px;
		opacity: 1;
		border-top-color: transparent;
		border-left-color: transparent;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}
