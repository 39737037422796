.client {
	.header-area {
		position: relative;
		width: 100%;
		z-index: 99;
		transition: background 0.4s, all 0.3s linear;
		@media (max-width: 991px) {
			position: fixed;
			padding: 10px 0px;
			top: 0px;
			background: $primary-color;
		}
		@media (max-width: 575px) {
			padding: 10px 20px;
		}
		.navbar-collapse {
			@media (max-width: 991px) {
				margin-top: 20px;
			}
		}
		.navbar {
			background: transparent;
			padding: 0px;
			border: 0px;
			border-radius: 0px;
			.navbar-toggler {
				color: $white;
				font-size: 20px;
			}
			.nav {
				.nav-item {
					margin-right: 45px;
					.nav-link {
						font: 500 12px/80px $primary-font;
						text-transform: uppercase;
						color: #fff;
						padding: 0px;
						display: inline-block;
						&:after {
							display: none;
						}
						@media (max-width: 991px) {
							font: 500 12px/40px $primary-font;
						}
					}
					&:hover,
					&.active {
						.nav-link {
						}
					}
					&.submenu {
						position: relative;
						ul {
							border: none;
							padding: 0px;
							border-radius: 0px;
							box-shadow: none;
							margin: 0px;
							background: #fff;
							@media (min-width: 992px) {
								position: absolute;
								top: 120%;
								left: 0px;
								min-width: 200px;
								text-align: left;
								opacity: 0;
								transition: all 300ms ease-in;
								visibility: hidden;
								display: block;
								border: none;
								padding: 0px;
								border-radius: 0px;
							}
							&:before {
								content: "";
								width: 0;
								height: 0;
								border-style: solid;
								border-width: 10px 10px 0 10px;
								border-color: #eeeeee transparent transparent transparent;
								position: absolute;
								right: 24px;
								top: 45px;
								z-index: 3;
								opacity: 0;
								transition: all 400ms linear;
							}
							.nav-item {
								display: block;
								float: none;
								margin-right: 0px;
								border-bottom: 1px solid #ededed;
								margin-left: 0px;
								transition: all 0.4s linear;
								.nav-link {
									line-height: 45px;
									color: $primary-color;
									padding: 0px 30px;
									transition: all 150ms linear;
									display: block;
									margin-right: 0px;
								}
								&:last-child {
									border-bottom: none;
								}
								&:hover {
									.nav-link {
										background: $primary-color;
										color: #fff;
									}
								}
							}
						}
						&:hover {
							ul {
								@media (min-width: 992px) {
									visibility: visible;
									opacity: 1;
									top: 100%;
								}
								.nav-item {
									margin-top: 0px;
								}
							}
						}
					}
					&:last-child {
						margin-right: 0px;
					}
				}
			}
			.search-bar {
				font-size: 12px;
				line-height: 60px;
				display: inline-block;
				color: $white;
				margin-left: 195px;
				@media (max-width: 1200px) and (min-width: 992px) {
					margin-left: 50px;
				}
				@media (max-width: 991px) {
					margin-left: 0px;
					margin-top: 10px;
				}
				.ti-search {
					color: $text-color;
				}
				input {
					color: $text-color !important;
					@include placeholder {
						color: $text-color;
					}
					&:focus {
						color: $white !important;
						font-weight: 300;
					}
				}
			}
		}
		&.navbar_fixed {
			.main_menu {
				position: fixed;
				width: 100%;
				top: -70px;
				left: 0;
				right: 0;
				background-image: -moz-linear-gradient(0deg, rgb(20, 29, 162) 0%, rgb(155, 92, 246) 100%);
				background-image: -webkit-linear-gradient(0deg, rgb(20, 29, 162) 0%, rgb(155, 92, 246) 100%);
				background-image: -ms-linear-gradient(0deg, rgb(20, 29, 162) 0%, rgb(155, 92, 246) 100%);
				transform: translateY(70px);
				transition: transform 500ms ease, background 500ms ease;
				-webkit-transition: transform 500ms ease, background 500ms ease;
				box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
				.navbar {
					.nav {
						.nav-item {
							.nav-link {
								line-height: 70px;
							}
						}
					}
				}
			}
		}
	}
	&.light,
	&.color {
		.header-area .navbar .nav .nav-item .nav-link {
			color: $primary-color;
		}
	}
}
